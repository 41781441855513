import React, { useState, useEffect, memo } from 'react'
import './TypingText.css'
import clsx from 'clsx'

const TypingText = ({ text, typingSpeed = 60, step, isTextVisible }) => {
    const [index, setIndex] = useState(0)

    useEffect(() => {
        setIndex(0)

        const delayTimeout = setTimeout(() => {
            const interval = setInterval(() => {
                setIndex(prevIndex => {
                    if (prevIndex < text.length) {
                        return prevIndex + 1
                    } else {
                        clearInterval(interval)
                        return prevIndex
                    }
                })
            }, typingSpeed)

            return () => clearInterval(interval)
        }, 1000)

        return () => clearTimeout(delayTimeout)
    }, [text, typingSpeed])

    const renderedText = text
        .slice(0, index)
        .split('\n')
        .map((line, idx) => (
            <React.Fragment key={idx}>
                {line}
                <br />
            </React.Fragment>
        ))

    return (
        <p
            className={clsx(
                'tutorial_text',
                { small: step === 8 || step === 9 || step === 13 || step === 15 },
                { not_visible: !isTextVisible || step === 12 || step === 14 },
            )}
        >
            {renderedText}
        </p>
    )
}

export default memo(TypingText)
