import React, { useState, useEffect } from 'react'
import './LevelInfo.css'
import giftIcon from '../../images/icons/gift.png'
import InformationSection from './../InformationSection/InformationSection'
import { toast } from 'react-toastify'
import { getPlayerInvitingStatus, getPlayerLevel } from '../../api/playersApi'
import { sounds } from '../../utils'
import RangInfo from './../RankInfo/RankInfo'
import clsx from 'clsx'

const BOT_URL = process.env.REACT_APP_BOT_URL

const LevelInfo = ({ closePopup, selectedCharacter, player, level, step }) => {
    const [showPopup, setShowPopup] = useState(false)
    const [popupContent, setPopupContent] = useState('')
    const [showGiftPopup, setShowGiftPopup] = useState(false)
    const [giftPopupContent, setGiftPopupContent] = useState('')
    const [invitingStatus, setInvitingStatus] = useState(null)
    const [playerLevelInfo, setPlayerLevelInfo] = useState(null)
    const [showRangInfo, setShowRangInfo] = useState(false)

    useEffect(() => {
        console.log('useEffect:')
        if (step) return

        const fetchPlayerInvitingStatusAndLevelInfo = async () => {
            try {
                const [status, levelInfo] = await Promise.all([
                    getPlayerInvitingStatus(player.telegram_id),
                    getPlayerLevel(player.telegram_id),
                ])

                setInvitingStatus(status)
                setPlayerLevelInfo(levelInfo)
            } catch (error) {
                console.error('Failed to fetch inviting status:', error)
                toast.error('Failed to load inviting status. Please try again later.', {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
        }

        fetchPlayerInvitingStatusAndLevelInfo()
    }, [player?.telegram_id, step])

    const handleCopy = referralLink => {
        navigator.clipboard
            .writeText(referralLink)
            .then(() => {
                toast.success('Referral link has been copied to your clipboard!', {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
            .catch(error => {
                toast.error('Failed to copy the link. Please try again.', {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }

    const handleShare = () => {
        const referralLink = `${BOT_URL}${player.referral_code}`
        sounds.approve.play()

        if (navigator.share) {
            navigator
                .share({
                    title: 'Join CapiClicker',
                    text: `Join CapiClicker and start earning rewards! Use my referral link: ${referralLink}`,
                    url: referralLink,
                })
                .catch(error => console.log('Error sharing', error))
        } else {
            toast.info('Sharing is not supported in your browser. The referral link has been copied to your clipboard.', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
        handleCopy(referralLink)
    }

    // Function to open Telegram share dialog
    const handleTelegramShare = () => {
        if (step) return
        sounds.approve.play()
        const referralLink = `${BOT_URL}${player.referral_code}`
        const message = `Join CapiClicker and start earning rewards! Use my referral link: ${referralLink}`

        // Using Telegram's URL scheme to open the contact selection screen with a prefilled message
        const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(message)}`

        // Opens Telegram with prefilled data, allowing the user to select contacts
        window.open(telegramUrl, '_blank')
    }

    const openRangInfo = () => {
        console.log('openRangInfo:')
        setShowRangInfo(true)
    }

    const closeRangInfo = () => {
        sounds.close.play()
        setShowRangInfo(false)
    }

    const openPopup = () => {
        sounds.close.play()
        setPopupContent(
            <div>
                <p>How to become the most famous POOMB CO-OWNER?</p>
                <p> Invite as many friends of yours as you can!</p>
                <p> You can receive 150 000 $COGA TOKENS and Transform them into the REAL MONEY!</p>
                <p> What's $COGA TOKEN?</p>
                <p>
                    Comet Games Token (COGA) is a native token created by gamedev company Comet Space. Poomb Creatures is the first
                    company's project with COGA Token.
                </p>
                <p> What's POOMB CREATURES?</p>
                <p>
                    It' s a whole ecosystem of Telegram clicker, NFTs, Crypto Exchange, Tokens, real money and mobile game for the iOS Store
                    and Google Play Market platforms that combines popular elements of the Tamagotchi genre with advanced blockchain
                    technologies.
                </p>
            </div>,
        )
        setShowPopup(true)
    }

    const closePopupHandler = () => {
        sounds.close.play()
        setShowPopup(false)
    }

    // gift popup
    const openGiftPopup = () => {
        sounds.close.play()
        setGiftPopupContent(
            <div>
                <p>
                    You have a great <br /> opportunity to get <br />
                    150 000 $COGA TOKENS <br />
                    before the main POOMB GAME starts!
                </p>
                <p>
                    Invite friends using your referral link <br />
                    so you wouldn't miss the chance to
                </p>
                <p>EARN REAL MONEY!</p>
                <div className="gift-content">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    LVL 1 - 1 FRIEND
                                    <br />
                                    You get - 150 $COGA
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    LVL 2 - 2 FRIEND (1)
                                    <br />
                                    You get - 300 $COGA
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    LVL 3 - 5 FRIEND (3)
                                    <br />
                                    You get - 450 $COGA
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    LVL 4 - 9 FRIEND (4)
                                    <br />
                                    You get - 600 $COGA
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    LVL 5 - 17 FRIEND (8)
                                    <br />
                                    You get - 750 $COGA
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    LVL 6 - 33 FRIEND (16)
                                    <br />
                                    You get - 1050 $COGA
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    LVL 7 - 65 FRIEND (32)
                                    <br />
                                    You get - 8700 $COGA
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    LVL 8 - 129 FRIEND (64)
                                    <br />
                                    You get - 12000 $COGA
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    LVL 9 - 257 FRIEND (128)
                                    <br />
                                    You get - 13500 $COGA
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    LVL 10 - 513 FRIEND (256)
                                    <br />
                                    You get - 15000 $COGA
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    LVL 11 - 1025 FRIEND (512)
                                    <br />
                                    You get - 16500 $COGA
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    LVL 12 - 2049 FRIEND (1024)
                                    <br />
                                    You get - 18000 $COGA
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    LVL 13 - 4097 FRIEND (2048)
                                    <br />
                                    You get - 19500 $COGA
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    LVL 14 - 8193 FRIEND (4096)
                                    <br />
                                    You get - 21000 $COGA
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    LVL 15 - 16385 FRIEND (8192)
                                    <br />
                                    You get - 22500 $COGA
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>,
        )
        setShowGiftPopup(true)
    }

    const closeGiftPopupHandler = () => {
        sounds.close.play()
        setShowGiftPopup(false)
    }

    const progressPercentage =
        playerLevelInfo && playerLevelInfo.player_total_clicks && playerLevelInfo.clicks_to_next_lvl > 0
            ? Math.floor(
                  ((playerLevelInfo.player_total_clicks - playerLevelInfo.clicks_needed_for_current_lvl) /
                      (playerLevelInfo.clicks_to_next_lvl - playerLevelInfo.clicks_needed_for_current_lvl)) *
                      100,
              )
            : playerLevelInfo && playerLevelInfo.player_total_clicks && !playerLevelInfo.clicks_to_next_lvl
            ? 100
            : 0

    console.log('playerLevelInfo:', playerLevelInfo)
    return (
        <div className="level-info-overlay">
            <div className="level-info-content">
                <img src={require('../../images/leaf-frame-popup.webp')} alt="" className="level-leaf-image" />
                <div className={clsx('level-close-btn', { close_btn_tutorial: step })} onClick={closePopup}>
                    <img src={require('../../images/icons/close.png')} alt="Close" className="close-icon" />
                </div>
                <div className="account-pet-wrapper">
                    <div className="level-item-wrapper">
                        <div className="pet-wrapper">
                            <div className={`selected-pet-icon ${selectedCharacter}`}></div>
                        </div>
                        <h2>{playerLevelInfo ? playerLevelInfo.current_lvl : level} LVL</h2>
                    </div>
                </div>
                <div className="level-bar-wrapper">
                    <div className="level-progress-bar">
                        <div className="level-progress" style={{ width: `${progressPercentage}%` }}></div>
                    </div>
                    <div className="level-title">
                        {playerLevelInfo?.clicks_to_next_lvl === 0 ? (
                            <p>
                                You have reached
                                <br /> the MAX level.
                            </p>
                        ) : (
                            <p>
                                Tap to increase
                                <br />
                                Poomb level
                            </p>
                        )}
                    </div>
                </div>
                <div className={clsx('rang-wrapper', { rang_wrapper_tutorial: step })}>
                    <div className="rang-btn" onClick={openRangInfo}>
                        <p className="rang-text">{step ? '3' : '-'}</p>
                    </div>
                    <div className="leaderboard-wrapper">
                        <h4>Rank {step ? '124' : '-'}</h4>
                        <p>leaderboard and achievements</p>
                    </div>
                    <div className="gift-wrapper" onClick={openGiftPopup}>
                        <img src={giftIcon} alt="Gift Icon" className="gift-icon" />
                    </div>
                </div>
                <div className="information-wrapper">
                    <p>
                        Don't miss the chance to <br />
                        GET up to 150 000 $COGA TOKENS inviting your <br />
                        friends
                    </p>
                </div>
                <div className="friends-wrapper">
                    <div className="friends-info-wrapper">
                        <div className="friends-info">
                            <div className="left-item-info">
                                <p className="total-text">{invitingStatus?.referrals_count}</p>
                                <p className="left-text">{invitingStatus?.next_reward?.friends_needed} FRIENDS LEFT</p>
                            </div>
                        </div>
                    </div>
                    <div className="invite-text-wrapper" onClick={handleTelegramShare}>
                        <p>INVITE FRIENDS</p>
                    </div>
                    <div className="btn-invite-wrapper">
                        {step === 11 && <div className="tutor_lvl_step_backdrop" />}
                        <div className="btn-wrapper">
                            <button className={clsx('invite-btn', { tutorial_active: step })} onClick={handleShare}></button>
                            <p>
                                Get {invitingStatus?.next_reward?.reward} <br />
                                COGA
                            </p>
                        </div>
                        <div onClick={openPopup} className={clsx('info-btn-wrapper', { tutorial_active: step })}>
                            <img src={require('../../images/icons/info-icon.png')} alt="Info popup" className={clsx('info-btn-icon')} />
                        </div>
                    </div>
                </div>
            </div>

            {showPopup && <InformationSection onClose={closePopupHandler} content={popupContent} buttonText="CooL" />}

            {showGiftPopup && <InformationSection onClose={closeGiftPopupHandler} content={giftPopupContent} buttonText="CooL" />}

            {showRangInfo && <RangInfo closeRangInfo={closeRangInfo} />}
        </div>
    )
}

export default LevelInfo
