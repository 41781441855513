import React, { useState } from 'react'
import './PetSelectionStep.css'
import CharacterSelection from '../../CharacterSelection/CharacterSelection'
import clsx from 'clsx'

const PetSelectionStep = ({ toggleShowTutor, toggleShowTutorText, step, handleNextStep, ...props }) => {
    const [showBackdrop, setShowBackdrop] = useState(true)

    const toggleShowBackdrop = () => {
        if (showBackdrop) {
            setShowBackdrop(false)
            // toggleShowTutorText()
        }
        // toggleShowTutor()
    }

    return (
        <div>
            <div className={clsx('pet_selection_backdrop', { no_visible: !showBackdrop })} />
            <CharacterSelection toggleShowBackdrop={toggleShowBackdrop} step={step} handleNextStep={handleNextStep} {...props} />
        </div>
    )
}

export default PetSelectionStep
