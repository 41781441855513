import React, { useState } from 'react'
import './FormStep.css'
import clsx from 'clsx'
import { sendNickname, addCharacterToPlayer } from '../../../api/playersApi'

const FormStep = ({ selectCharacter, userId, selectedCharacter = 'main' }) => {
    console.log('selectedCharacter:', selectedCharacter)
    const [nickname, setNickname] = useState('')
    const [error, setError] = useState('')
    const [sendError, setSendError] = useState('')
    const [loading, setLoading] = useState(false)

    const handleInputChange = e => {
        if (sendError) {
            setSendError('')
        }
        const value = e.target.value.trim()
        setNickname(value)

        if (/[^a-zA-Z\d_]/.test(value)) {
            setError('Nickname can contain only English letters, numbers, and underscores.')
        } else if (value.length > 20) {
            setError('Nickname must be no more than 20 characters.')
        } else if (value.length < 3) {
            setError('Nickname must be at least 3 characters long.')
        } else {
            setError('')
        }
    }

    const handleSubmit = async e => {
        e.preventDefault()

        if (error || !nickname) return

        setLoading(true)
        try {
            await sendNickname(userId, nickname)
            await addCharacterToPlayer(userId, selectedCharacter)
            selectCharacter(selectedCharacter)
        } catch (error) {
            console.error('Failed to send nickname:', error)
            setSendError('Failed to send nickname. Please try again.')
            setLoading(false)
        }
    }

    return (
        <div className="form_wrapper">
            <form onSubmit={handleSubmit}>
                <img src={require('../../../images/leaf-frame-popup.webp')} alt="" className="level-leaf-image" />
                <label>
                    <span className="label_text">Enter your nickname:</span>
                    <input type="text" value={nickname} onChange={handleInputChange} disabled={loading} ня />
                    {error && <p className="error_message">{error}</p>}
                    {sendError && <p className="error_message">{sendError}</p>}
                </label>

                <button
                    className={clsx('achieve-action-btn', 'submit_button', {
                        'achieve-blue-action-btn': !!error || !nickname || loading,
                        'achieve-green-action-btn': nickname && !error && !loading,
                    })}
                    type="submit"
                    disabled={!!error || !nickname || loading}
                >
                    {loading ? 'Loading...' : 'Submit'}
                </button>
            </form>
        </div>
    )
}

export default FormStep
