import React, { useState } from 'react'
import './PetTapStep.css'

import clsx from 'clsx'
import Gameplay from '../../Gameplay/Gameplay'

const PetTapStep = ({ toggleShowTutor, selectedCharacter, step, handleNextStep, ...props }) => {
    const [showBackdrop, setShowBackdrop] = useState(true)

    const toggleShowBackdrop = () => {
        setShowBackdrop(false)
        toggleShowTutor()
    }

    return (
        <div>
            <div
                className={clsx('pet_tap_backdrop', {
                    no_visible: !showBackdrop || step === 6 || step === 11 || step === 12 || step === 13 || step === 14,
                })}
            />
            <Gameplay selectedCharacter={selectedCharacter} tutorial step={step} handleNextStep={handleNextStep} />
        </div>
    )
}

export default PetTapStep
