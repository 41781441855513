const tutorialStepsText = Object.freeze({
    step1: 'To start\nplaying, first\nsubscribe.',
    step2: "Now let's\nplay and earn!",
    step3: 'At first,\nchoose your\nfriend.',
    step4: 'Now you can\nselect a friend!',
    step5: 'Start earning\nby tapping\non the pet',
    step6: 'Welcome to\nthe 6 step!',
    step7: "Energy for\npet's tap",
    step8: 'Your COGA points\nthat you can\nexchange for real\nmoney',
    step9: 'A storage\nthat fills up\nautomatically.\nDon’t forget to clear it.',
    step10: 'Your level\nand extra\nbonuses',
    step11: 'Invite friends.\nRank up.\nEarn even more.',
    step12: 'Welcome to\nthe 12 step!',
    step13: 'Upgrade your\naccount\nto be the coolest\nand richest!',
    step14: 'Welcome to\nthe 14 step!',
    step15: "Don't forget\nabout tasks that\nwill give you a turbo boost.",
    step16: 'Great! Now set\nyour nickname\nand start earning!',
})

export default tutorialStepsText
