import React, { useState, useEffect, memo } from 'react'
import { calculateTimeToNextTick, formatTime } from '../../utils'

const EnergyTimer = ({ energy, setEnergy, maxEnergyValue, sendMessage, player, tutorial }) => {
    const [totalTimeLeft, setTotalTimeLeft] = useState(() => calculateTimeToNextTick())

    useEffect(() => {
        if (energy < maxEnergyValue) {
            const timeToNextTick = calculateTimeToNextTick()
            setTotalTimeLeft(timeToNextTick)
            const timer = setInterval(() => {
                setTotalTimeLeft(prev => {
                    if (prev === 1) {
                        if (!tutorial) {
                            console.log('prev:', prev)
                            sendMessage(
                                JSON.stringify({
                                    operation: 'read_player',
                                    data: {
                                        telegram_id: player.telegram_id,
                                    },
                                }),
                            )
                        }

                        return calculateTimeToNextTick()
                    } else {
                        return prev - 1
                    }
                })
            }, 1000)

            return () => clearInterval(timer)
        } else {
            setTotalTimeLeft(0)
        }
    }, [energy, maxEnergyValue, sendMessage, setEnergy, player])

    return <p className="timer-text">{energy < maxEnergyValue ? formatTime(totalTimeLeft) : '00:00'}</p>
}

export default memo(EnergyTimer)
