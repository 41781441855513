import { useState, useEffect, useCallback } from 'react'
import Start from '../Start/Start'
import Robot from '../../assets/robot.PNG'
import clsx from 'clsx'

import './Tutorial.css'
import tutorialStepsText from '../../constants/TutorialText'
import TypingText from './TypingText/TypingText'
import PetSelectionStep from './PetSelectionStep/PetSelectionStep'
import PetTapStep from './PetTapStep/PetTapStep'
import { sounds } from '../../utils'
import FormStep from './FormStep/FormStep'

const Tutorial = ({ isSubscribed, selectCharacter, userId }) => {
    const [step, setStep] = useState(1)
    const [selectedCharacter, setSelectedCharacter] = useState('')
    const [showTutor, setShowTutor] = useState(true)
    const [showTutorText, setShowTutorText] = useState(true)

    const toggleShowTutor = () => {
        setShowTutor(false)
    }

    const toggleShowTutorText = () => {
        setShowTutorText(!showTutorText)
    }

    useEffect(() => {
        if (showTutorText) return
        toggleShowTutorText()
    }, [step])

    console.log('step:', step)
    useEffect(() => {
        if (isSubscribed) {
            setStep(2)
        } else {
            setStep(1)
        }
    }, [isSubscribed])

    const handleNextStep = useCallback(() => {
        console.log('handleNextStep:')
        if (step < 16) {
            setStep(prevStep => prevStep + 1)
        } else {
            console.log('Ready')
        }
    }, [step])

    const handleCharacterSelection = useCallback(
        nameCharacter => {
            sounds.select.play()
            setSelectedCharacter(nameCharacter)
            handleNextStep()
        },
        [handleNextStep],
    )

    return (
        <div className="tutorial_container">
            {step === 1 && <Start tutorial={true} isSubscribed={isSubscribed} />}
            {step === 2 && <Start tutorial={true} isSubscribed={isSubscribed} handleNextStep={handleNextStep} />}
            {(step === 3 || step === 4) && (
                <PetSelectionStep
                    handleNextStep={handleNextStep}
                    handleSelectClick={handleCharacterSelection}
                    toggleShowTutor={toggleShowTutor}
                    toggleShowTutorText={toggleShowTutorText}
                    step={step}
                />
            )}

            {step !== 1 && step !== 2 && step !== 3 && step !== 4 && step !== 16 && (
                <PetTapStep selectedCharacter={selectedCharacter} step={step} handleNextStep={handleNextStep} />
            )}

            {step === 16 && <FormStep selectCharacter={selectCharacter} userId={userId} /* selectedCharacter={selectedCharacter}  */ />}

            <div
                className={clsx(
                    'tutorial_robot_text_wrapper',
                    { no_visible: step === 6 },
                    { 'tutorial_robot_text_wrapper--raised': step === 3 || step === 4 || step === 12 || step === 14 },
                    { 'tutorial_robot_text_wrapper--very-raised': step === 13 },
                    { 'tutorial_robot_text_wrapper--lowered': step === 16 },
                )}
            >
                <div className="tutorial_img_wrapper">
                    <img src={Robot} alt="Robot" width={200} className={clsx('tutorial_robot_img')} />
                </div>
                <div className={clsx('btn_text_container', { button_visible: step === 5 })}>
                    <div className="tutorial_text_wrapper">
                        <TypingText text={tutorialStepsText[`step${step}`]} typingSpeed={30} step={step} isTextVisible={showTutorText} />
                    </div>
                    <button
                        type="button"
                        onClick={handleNextStep}
                        className={clsx('btn_try', {
                            hidden:
                                step === 1 ||
                                step === 2 ||
                                step === 3 ||
                                step === 4 ||
                                step === 12 ||
                                step === 13 ||
                                step === 14 ||
                                step === 16,
                        })}
                    >
                        {step === 5 ? 'Try' : 'Next'}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Tutorial
