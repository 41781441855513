import React, { useState, useEffect, useRef } from 'react'
import './AchieveMenu.css'
import clsx from 'clsx'
import LeftDisableMenuIcon from '../../images/icons/left-menu.webp'
import RightMenuIcon from '../../images/icons/right-menu-disable.webp'
import { getSocialSubscriptions, updateSocialSubscription, getRewardForSubscription } from '../../api/playersApi'
import { subscriptionNames } from '../../constants/subscriptionNames'
import { sounds } from '../../utils/sounds'
import { FaFacebook, FaInstagram, FaYoutube, FaTiktok, FaDiscord, FaLinkedin, FaShareAlt } from 'react-icons/fa'
import { SiTelegram } from 'react-icons/si'
import { FaXTwitter } from 'react-icons/fa6'
import RewardCoinAnimation from './../RewardCoinAnimation/RewardCoinAnimation'
import socialMediaRewardsForTutorial from '../../constants/socialMediaRewardsForTutorial'

const AchieveMenu = ({ closeMenu, telegramId = null, earnCoinsForSubscription, openInfoMenu }) => {
    const [subscriptions, setSubscriptions] = useState({})
    const [loading, setLoading] = useState({})
    const [pendingSubscription, setPendingSubscription] = useState(null)
    const [activeReward, setActiveReward] = useState(null)
    const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 })
    const buttonRefs = useRef({})
    const coinIconRefs = useRef({})
    const timerIdRef = useRef(null)

    useEffect(() => {
        if (!telegramId) {
            setSubscriptions(socialMediaRewardsForTutorial)
            return
        }
        const fetchSubscriptions = async () => {
            setLoading(true)
            try {
                const cachedSubscriptions = localStorage.getItem(`subscriptions_${telegramId}`)
                if (cachedSubscriptions) {
                    setSubscriptions(JSON.parse(cachedSubscriptions))
                }

                const freshData = await getSocialSubscriptions(telegramId)
                setSubscriptions(freshData)
                localStorage.setItem(`subscriptions_${telegramId}`, JSON.stringify(freshData))
            } catch (error) {
                console.error('Error fetching social subscriptions:', error)
            } finally {
                setLoading(false)
            }
        }

        fetchSubscriptions()
    }, [telegramId])

    const getSocialIcon = key => {
        if (key.includes('instagram')) {
            return <FaInstagram />
        }
        if (key.includes('facebook')) {
            return <FaFacebook />
        }
        if (key.includes('tiktok')) {
            return <FaTiktok />
        }
        if (key.includes('x')) {
            return <FaXTwitter />
        }
        if (key.includes('youtube')) {
            return <FaYoutube />
        }
        if (key.includes('discord')) {
            return <FaDiscord />
        }
        if (key.includes('telegram')) {
            return <SiTelegram />
        }
        if (key.includes('linkedin')) {
            return <FaLinkedin />
        }
        if (key.includes('website')) {
            return <FaShareAlt />
        }
        return null
    }

    const handleButtonClick = async event => {
        const rewardType = event.currentTarget.getAttribute('data-reward')
        const subscription = subscriptions[rewardType]

        if (!subscription) return

        setLoading(prevLoading => ({ ...prevLoading, [rewardType]: true }))

        if (subscription.subscribed) {
            sounds.reward.play()

            const coinElement = coinIconRefs.current[rewardType]
            if (coinElement) {
                const rect = coinElement.getBoundingClientRect()
                const centerX = rect.left + rect.width / 2
                const centerY = rect.top + rect.height / 2
                setClickPosition({ x: centerX, y: centerY })
                setActiveReward(rewardType)
            }

            try {
                const rewardResponse = await getRewardForSubscription(telegramId, rewardType)
                setSubscriptions(rewardResponse)
                localStorage.setItem(`subscriptions_${telegramId}`, JSON.stringify(rewardResponse))

                if (!subscription.reward_obtained) {
                    earnCoinsForSubscription(subscription.tokens)
                }
            } catch (error) {
                console.error('Error obtaining reward:', error)
            } finally {
                setLoading(prevLoading => ({ ...prevLoading, [rewardType]: false }))
            }
        } else {
            sounds.click.play()
            window.open(subscription.link, '_blank')
            setPendingSubscription(rewardType)
            setLoading(prevLoading => ({ ...prevLoading, [rewardType]: false }))
        }
    }

    useEffect(() => {
        if (pendingSubscription) {
            if (timerIdRef.current) {
                clearTimeout(timerIdRef.current)
            }

            timerIdRef.current = setTimeout(() => {
                checkPendingSubscription()
            }, 10000)
        }

        return () => {
            if (timerIdRef.current) {
                clearTimeout(timerIdRef.current)
            }
        }
    }, [pendingSubscription])

    const checkPendingSubscription = () => {
        if (!document.hidden && pendingSubscription) {
            updateSocialSubscription(telegramId, pendingSubscription)
                .then(updatedSubscription => {
                    setSubscriptions(prevSubscriptions => ({
                        ...prevSubscriptions,
                        [pendingSubscription]: {
                            ...prevSubscriptions[pendingSubscription],
                            ...updatedSubscription[pendingSubscription],
                            subscribed: true,
                        },
                    }))
                    localStorage.setItem(`subscriptions_${telegramId}`, JSON.stringify(subscriptions))
                    setPendingSubscription(null)
                })
                .catch(error => console.error('Error updating subscription:', error))
        } else if (!pendingSubscription) {
            if (timerIdRef.current) {
                clearTimeout(timerIdRef.current)
                timerIdRef.current = null
            }
        }
    }

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (!document.hidden) {
                checkPendingSubscription()
            }
        }

        const handleFocus = () => {
            checkPendingSubscription()
        }

        window.addEventListener('focus', handleFocus)
        document.addEventListener('visibilitychange', handleVisibilityChange)

        return () => {
            window.removeEventListener('focus', handleFocus)
            document.removeEventListener('visibilitychange', handleVisibilityChange)
            if (timerIdRef.current) {
                clearTimeout(timerIdRef.current)
            }
        }
    }, [pendingSubscription, telegramId])

    const isButtonDisabled = rewardType => subscriptions[rewardType]?.reward_obtained || loading[rewardType]

    const getButtonClass = rewardType =>
        clsx({
            'achieve-green-action-btn': subscriptions[rewardType]?.subscribed,
            'achieve-blue-action-btn': !subscriptions[rewardType]?.subscribed,
        })

    const renderSubscriptionRows = () =>
        Object.entries(subscriptions).map(
            ([key, subscription]) =>
                subscriptionNames[key] && (
                    <tr key={key}>
                        <td>
                            <div className="frame-block">
                                <div className="social-icon">{getSocialIcon(key)}</div>
                            </div>
                        </td>
                        <td>
                            <p>{subscriptionNames[key]}</p>
                        </td>
                        <td style={{ position: 'relative' }}>
                            <button
                                ref={el => (buttonRefs.current[key] = el)}
                                className={getButtonClass(key)}
                                data-reward={key}
                                onClick={handleButtonClick}
                                disabled={isButtonDisabled(key)}
                            >
                                Get{' '}
                                <img
                                    ref={el => (coinIconRefs.current[key] = el)}
                                    src={require('../../images/icons/coin.png')}
                                    alt="Coin"
                                    className="coin-btn-icon"
                                />{' '}
                                {subscription.tokens}
                            </button>
                            {activeReward === key && (
                                <RewardCoinAnimation
                                    trigger={activeReward === key}
                                    startX={clickPosition.x}
                                    startY={clickPosition.y}
                                    onComplete={() => setActiveReward(null)}
                                />
                            )}
                        </td>
                    </tr>
                ),
        )

    const handleCloseMenu = () => {
        sounds.close.play()
        closeMenu()
    }

    const handleOpenInfoMenu = () => {
        openInfoMenu()
    }

    return (
        <div className="achieve-menu-overlay" onClick={handleCloseMenu}>
            <div className="achieve-menu-content" onClick={e => e.stopPropagation()}>
                {/* <img src={require('../../images/leaf-frame-popup.webp')} alt="" className="leaf-image" /> */}
                <div className="achievements-header">
                    <div className="achieve-menu-item">
                        <div className="menu-btn" onClick={handleOpenInfoMenu}>
                            <img src={LeftDisableMenuIcon} alt="Menu" className="menu-icon" />
                        </div>
                        <div className="menu-btn">
                            <img src={RightMenuIcon} alt="Menu" className="menu-icon" />
                        </div>
                    </div>
                    <div className="close-menu-btn" onClick={handleCloseMenu}>
                        <img src={require('../../images/icons/close.png')} alt="Close" className="close-menu-icon" />
                    </div>
                </div>
                <div className="achievements-table-wrapper">
                    <table className="achievements-table">
                        <tbody>{renderSubscriptionRows()}</tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default AchieveMenu
